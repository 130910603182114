<template>
  <b-modal
    id="package-details"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    centered
    title="PACKAGE DETAILS"
  >
    <b-overlay
      id="overlay-background"
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-center align-items-center"
        >
          <b-card>
            <b-row>
              <b-col
                lg="3"
                sm="12"
                class="border-right d-flex justify-content-center align-items-center"
                style="height: 150px;"
              >
                <div style="position: absolute; top: 0; left: 100px;">
                  <h4
                    class="font-weight-bolder mb-1"
                    style="font-size: 1.5rem;"
                  >
                    Current Usage
                  </h4>
                </div>
              </b-col>
              <b-col
                lg="3"
                sm="12"
                class="border-right d-flex justify-content-center align-items-center"
              >
                <div style="position: absolute; top: 0; right: 30px;">
                  <b-button
                    v-if="activeSubscription.package.has_trial"
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="relief-warning"
                    pill
                    class="btn-sm align-items-left"
                  >
                    ON TRIAL
                  </b-button>
                  <b-button
                    v-else
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="relief-warning"
                    pill
                    class="btn-sm align-items-left"
                    :style="{ backgroundColor: activeSubscription.subscription_status === 'active' ? 'green' : 'red' }"
                  >
                    {{ activeSubscription.subscription_status === 'active' ? 'ACTIVE' : 'INACTIVE' }}
                  </b-button>
                  <h4
                    class="font-weight-bolder mb-1"
                    style="font-size: 1.5rem; margin-top: 13px; margin-left: 4px;"
                  >
                    {{ activeSubscriptionUsage.usage_order_count }} / {{ activeSubscription.package.is_unlimited === true ? 'Unlimited Orders' : activeSubscription.package.order_limit }}
                  </h4>
                  <h4
                    class="font-weight-bolder mb-1"
                    style="font-size: 1.0rem;"
                  >
                    {{ activeSubscription.start_date }} / {{ activeSubscription.end_date }}
                  </h4>
                  <h4
                    class="font-weight-bolder mb-1"
                    style="font-size: 1.0rem;"
                  >
                    {{ activeSubscription.package.package_name }}
                  </h4>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BModal,
  // BTable,
  BOverlay,
  VBModal,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

// const PackageRepository = RepositoryFactory.get('package')
const SubscriptionRepository = RepositoryFactory.get('subscription')

function initialState() {
  return {
    open: false,
    loading: false,
    subscriptionId: null,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BModal,
    BOverlay,
    BButton,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  mounted() {
    this.fetchActiveSubscription()
    this.fetchActiveSubscriptionUsage()
    this.fetchSubscriptionData()
  },
  methods: {
    // eslint-disable-next-line camelcase
    async openModal(subscription_id) {
      await Object.assign(this.$data, initialState())
      this.open = true
      // eslint-disable-next-line camelcase
      this.subscriptionId = subscription_id
      this.fetchSubscriptionData()
      this.fetchActiveSubscriptionUsage()
    },
    async fetchActiveSubscriptionUsage() {
      try {
        const { data } = (await SubscriptionRepository.getActiveSubscriptionUsage())
        this.activeSubscriptionUsage = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async fetchSubscriptionData() {
      try {
        this.loading = true
        const { data } = (await SubscriptionRepository.getSubscriptionDataById(this.subscriptionId))
        this.activeSubscription = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    onClickPackage(id) {
      this.$router.push({ name: 'view-package', params: { id } })
    },
  },
}
</script>
<style scoped>
</style>
